import { useEffect, useRef, useState } from "react";
import { Order } from "../features/order";
import { LocalStorageData, MenuData, OrderData } from "../types";
import {
  RedirectToErrorPage,
  IsGetSystemStatus,
  dateFormatter,
  convertToCategoryCountFormat,
  intervalPolling,
  IsOpenTime,
  GetIsMaintenance,
} from "../api/helper";
import { LoadingAnimation } from "../components/LoadingAnimation";
import { auth } from "../config/Firebase";
import { RedirectModal } from "../components/RedirectModal";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GetMenuData } from "../features/menu/api";
import { SwipeableMenu } from "../features/menu/SwipeableMenu";
import { Cart, DetailDialog } from "../features/order/components";
import {
  CantOrderTitle,
  OrderSubmit,
  TodayUserOrderGet,
} from "../features/order/api";
import {
  GetPayPayDetails,
  PayPayCreateQR,
  StripeCheckout,
} from "../features/payment/api";
import { useSnackbar } from "notistack";

export const Menu = () => {
  const [isSystemStatus, setIsSystemStatus] = useState<boolean>();
  const [isMaintenance, setIsMaintenance] = useState<boolean>(false);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [menu, setMenu] = useState<MenuData[]>([]);
  const [chosenMenu, setChosenMenu] = useState<MenuData>({} as MenuData);
  const [orderData, setOrderData] = useState<MenuData[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [orderDialog, setOrderDialog] = useState<boolean>(false);
  const [isGetMenu, setIsGetMenu] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [noPaymentTitle, setNoPaymentTitle] = useState<string[]>([]);
  const [notPayedOrder, setNotPayedOrder] = useState<OrderData[]>([]);
  const menuCategoryArray = useRef<string[]>([]);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //dev
  useEffect(() => {
    const localStorageOrderData = JSON.parse(
      localStorage.getItem("order") || "[]"
    ) as LocalStorageData;
    if (
      localStorageOrderData.orderData &&
      localStorageOrderData.date === dateFormatter(new Date())
    ) {
      setOrderData(localStorageOrderData.orderData);
      setTotalPrice(localStorageOrderData.totalPrice);
    }
    (async () => {
      if (!auth.currentUser?.uid) return;
      try {
        setIsSystemStatus(await IsGetSystemStatus());
        setIsMaintenance(await GetIsMaintenance());
        const menuData = await GetMenuData();
        menuCategoryArray.current = convertToCategoryCountFormat(menuData);
        setMenu(menuData);
        const todayOrder = await TodayUserOrderGet(auth.currentUser?.uid, [
          "ordered",
          "cooked",
        ]);
        const notPayedOrder = await TodayUserOrderGet(auth.currentUser?.uid, [
          "not_payed",
        ]);
        if (todayOrder.length !== 0) {
          enqueueSnackbar(
            <div className="japanese_R mx-auto text-white">
              未受け取りの注文があります
            </div>,
            {
              variant: "warning",
              autoHideDuration: 2000,
              onClick: () => {
                navigate(`/order/${todayOrder[0].id}`);
                closeSnackbar();
              },
            }
          );
        }
        if (notPayedOrder.length !== 0) {
          enqueueSnackbar(
            <div className="japanese_R mx-auto text-white">
              確認してください
            </div>,
            {
              variant: "error",
              autoHideDuration: 2000,
              onClick: () => {
                navigate(
                  `/check/${notPayedOrder[0].id}/${notPayedOrder[0].payment}`
                );
                closeSnackbar();
              },
            }
          );
        }
        setNotPayedOrder(notPayedOrder);
        setIsGetMenu(true);
      } catch (e) {
        setIsGetMenu(false);
        RedirectToErrorPage(e);
      }
    })();
  }, [closeSnackbar, enqueueSnackbar, navigate]);

  useEffect(() => {
    if (orderData.length === 0) {
      setChosenMenu({} as MenuData);
      setOrderDialog(false);
    }
  }, [orderData]);

  useEffect(() => {
    if (notPayedOrder.length === 0 || notPayedOrder[0].payment === "stripe")
      return;
    const data = notPayedOrder[0];
    const polling = intervalPolling(async () => {
      const paypayDetails = await GetPayPayDetails(data.id);
      if (paypayDetails.data.status === "COMPLETED") {
        clearInterval(polling);
        navigate(`/check/${data.id}/paypay`);
      }
    }, 2000);
  }, [navigate, notPayedOrder]);

  return (
    <div className="mx-auto max-w-3xl">
      {isGetMenu ? (
        <div>
          {(!isSystemStatus || isMaintenance) && (
            <Alert severity="error">
              {IsOpenTime() || isMaintenance
                ? "現在メンテナンス中のためシステムを停止しています。"
                : "午前11:00に営業開始します"}
            </Alert>
          )}
          <SwipeableMenu
            category={menuCategoryArray.current}
            menu={menu}
            setChosenMenu={setChosenMenu}
            setDetailDialogOpen={setDetailDialogOpen}
          />
          <Order
            open={orderDialog}
            onDelete={(e) => {
              let priceTimesCount = 0;
              const deleteOrder = orderData.filter((menu) => {
                if (menu.title === e.title) priceTimesCount++;
                return menu.title !== e.title;
              });
              const newTotalPrice = totalPrice - e.price * priceTimesCount;
              setOrderData(deleteOrder);
              setTotalPrice(newTotalPrice);
              const localSave: LocalStorageData = {
                orderData: deleteOrder,
                totalPrice: newTotalPrice,
                date: dateFormatter(new Date()),
              };
              localStorage.setItem("order", JSON.stringify(localSave));
            }}
            orderData={orderData}
            totalPrice={totalPrice}
            onPrev={() => {
              setChosenMenu({} as MenuData);
              setOrderDialog(false);
            }}
            onNext={async (payment) => {
              try {
                const cantOrderTitle = (await CantOrderTitle(
                  orderData
                )) as string[];
                if (cantOrderTitle.length === 0) {
                  const order = await OrderSubmit({
                    user: {
                      uid: auth.currentUser?.uid || "",
                      studentName: auth.currentUser?.displayName || "",
                      mailAddress: auth.currentUser?.email || "",
                    },
                    totalPrice: totalPrice,
                    menu: orderData,
                    payment: payment,
                  });
                  setTimeout(() => {
                    navigate("/");
                  }, 500);
                  payment === "paypay" && (await PayPayCreateQR(order));
                  payment === "stripe" && (await StripeCheckout(order));
                } else {
                  setNoPaymentTitle(cantOrderTitle);
                  setIsModal(true);
                }
              } catch (e) {
                RedirectToErrorPage(e);
              }
              const localSave: LocalStorageData = {
                orderData: [],
                totalPrice: 0,
                date: dateFormatter(new Date()),
              };
              localStorage.setItem("order", JSON.stringify(localSave));
            }}
          />
          <DetailDialog
            open={detailDialogOpen}
            isSystemStatus={isSystemStatus}
            isMaintenance={isMaintenance}
            menu={chosenMenu}
            onNext={(order, count) => {
              if (!order) return;
              const currentOrderData = orderData;
              [...Array(count)].forEach(() => {
                currentOrderData.push(order);
              });

              setOrderData(currentOrderData);
              setTotalPrice(totalPrice + order.price * count);
              const localSave: LocalStorageData = {
                orderData: currentOrderData,
                totalPrice: totalPrice + order.price * count,
                date: dateFormatter(new Date()),
              };
              localStorage.setItem("order", JSON.stringify(localSave));
              setDetailDialogOpen(false);
            }}
            onPrev={() => {
              setDetailDialogOpen(false);
            }}
            isAddCart={true}
          />
          {orderData.length !== 0 && (
            <Cart
              onClick={() => {
                setOrderDialog(true);
              }}
              totalOrderItemsCount={orderData.length}
              totalPrice={totalPrice}
            />
          )}
          <RedirectModal
            isModal={isModal}
            countTimer={150000}
            toURL="/"
            buttonText="メニュー画面に戻る"
            noPaymentTitle={noPaymentTitle}
          />
        </div>
      ) : (
        <LoadingAnimation type="jelly" />
      )}
    </div>
  );
};
