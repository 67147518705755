// anyを許容するdisable,後でPayPayやStripeのAPIのデータ構造調べて型定義する
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuData, OrderData, OrderListTypes } from "../types";
import {
  doc,
  getDocs,
  collection,
  query,
  where,
  getDoc,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../config/Firebase";
import { FirebaseError } from "@firebase/util";
import { AxiosError } from "axios";

export const hostUrl = window.location.protocol + "//" + window.location.host;
export const RandomID = () => {
  const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const N = 16;
  return Array.from(crypto.getRandomValues(new Uint8Array(N)))
    .map((n) => S[n % S.length])
    .join("");
};
export const Yesterday = () => {
  return new Date(new Date(Date.now()).getTime() - 1000 * 60 * 60 * 24);
};

export const RedirectToErrorPage = (errorText: string | unknown) => {
  window.location.href = `/error/${errorText}`;
};

export const generateErrorFirebaseAndAxiosErrors = (
  errorText: string,
  e: unknown
) => {
  console.error(e);
  const errorMessagePrams =
    e instanceof FirebaseError || e instanceof AxiosError
      ? `${errorText}+${e.message}+${e.name}+${e.code}`
      : `${errorText}+${e}`;
  // errorMessageをURLに入れるためにスペースをアンダーバーに変換,/はエンコードする,?はエンコードする
  return errorMessagePrams
    .replace(/ /g, "_")
    .replace(/\//g, "%2F")
    .replace(/\?/g, "%3F");
};

export const IsGetSystemStatus = async () => {
  try {
    const docSnap = await getDoc(doc(db, "system", "systemData"));
    if (!docSnap.exists()) throw "isStatusが存在しません。";
    return docSnap.data().isStatus;
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "システムの状態の取得に失敗しました。",
      e
    );
  }
};

export const GetIsMaintenance = async () => {
  try {
    const docSnap = await getDoc(doc(db, "system", "systemData"));
    if (!docSnap.exists()) throw "isMaintenanceが存在しません。";
    return docSnap.data().isMaintenance;
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "システムの状態の取得に失敗しました。",
      e
    );
  }
};

export const IsOpenTime = () => {
  const now = new Date();
  const nowTime = now.getHours() * 100 + now.getMinutes();
  return nowTime >= 1100 && nowTime <= 1330;
};

//当日の全ユーザのオーダーを取得
export const TodayAllOrderGet = async (docId: string, maxValue: number) => {
  try {
    const q = query(
      collection(db, docId),
      orderBy("date", "desc"),
      where("date", ">", Yesterday()),
      limit(maxValue)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data() as OrderData);
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "注文情報の取得に失敗しました。",
      e
    );
  }
};

export const Timer = (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("");
    }, time);
  });
};

export const afterToPage = async (
  countDownNumber: number,
  setTimeNumber: (num: number) => void,
  toUrl: string
) => {
  let timer = countDownNumber;
  setInterval(() => {
    timer--;
    setTimeNumber(timer);
  }, 1000);
  Timer(10000).then(() => {
    window.location.href = toUrl;
  });
};

export class NewTimer {
  private countTimer: any;

  constructor(
    private readonly msSecWaitTime: number,
    private readonly setTimeNumber: (time: number) => void,
    private readonly toUrl: string
  ) {
    this.msSecWaitTime = msSecWaitTime;
    this.setTimeNumber = setTimeNumber;
    this.toUrl = toUrl;
    this.countTimer = null;
  }

  startTimer = () => {
    if (this.countTimer !== null) {
      this.clearTimer();
    }
    return new Promise((resolve) => {
      this.countTimer = setTimeout(() => {
        resolve("");
      }, this.msSecWaitTime);
    });
  };

  clearTimer = () => {
    clearTimeout(this.countTimer);
    this.countTimer = null;
  };

  afterToPage = async () => {
    let time = this.msSecWaitTime / 1000;
    setInterval(() => {
      time--;
      this.setTimeNumber(time);
    }, 1000);
    await this.startTimer();
    window.location.href = this.toUrl;
  };
}

export const convertToTitleCountFormat = (dataArray: Array<MenuData>) => {
  const uniqueSet = new Set(dataArray.map((item) => item.title));
  const priceMap = new Map(dataArray.map((item) => [item.title, item.price]));
  return Array.from(uniqueSet).map((title) => {
    const count = dataArray.filter((x) => x.title === title).length;
    return { title, count, price: priceMap.get(title) } as OrderListTypes;
  });
};
export const convertToCategoryCountFormat = (dataArray: Array<MenuData>) => {
  const uniqueSet = new Set(dataArray.map((item) => item.category));
  return Array.from(uniqueSet)
    .map((category) => {
      return category;
    })
    .sort((a, b) => b.localeCompare(a));
};

export const dateFormatter = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

export const intervalPolling = (callback: () => void, interval: number) => {
  let count = 0;
  const timer = setInterval(() => {
    if (document.visibilityState === "visible") {
      callback();
      if (count++ > 4) clearInterval(timer);
    }
  }, interval);
  return timer;
};
